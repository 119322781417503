/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Epic Music"} description={"Epic Music"}>
        <Column className="pb--60 pt--60" name={"g512wyc3jiv"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Old Castle"}>
              </Title>

              <Text className="text-box" content={"Epická skladba<br>Epic piece"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" use={"page"} href={"/"} content={"Home"}>
              </Button>

              <YouTube embedId={"yJqkEcG5q3U"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"mm7vsh6sm5j"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Black And Bright"}>
              </Title>

              <Text className="text-box" content={"Polystylová skladba<br>Polystyle composition<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"R7xjfpuZcWw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vxqspauylf"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Hope"}>
              </Title>

              <Text className="text-box" content={"Nová skladba o naději<br>New composition depicting the hope<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"cCoNhD9NAqg"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lxx9l8l18u"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Fairy Tale"}>
              </Title>

              <Text className="text-box" content={"Studie v pohádkovém duchu<br>Study in fairy tale genre"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=8CVSv2ygC08&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=2"} href={"https://www.youtube.com/watch?v=8CVSv2ygC08&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=2"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Drama"}>
              </Title>

              <Text className="text-box" content={"Dramatická studie<br>Study in Drama genre"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=faZ9ZxKhyZA&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=9"} href={"https://www.youtube.com/watch?v=faZ9ZxKhyZA&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=9"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wj591vu24t"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Solstice"}>
              </Title>

              <Text className="text-box" content={"Fantazy studie<br>Study in fantasy genre<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=vHbF4s7kcxU&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=3"} href={"https://www.youtube.com/watch?v=vHbF4s7kcxU&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=3"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Brave Heart"}>
              </Title>

              <Text className="text-box" content={"Studie historického dramatu<br>Study in Historical drama genre"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=NXFxypRKfk8&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=7"} href={"https://www.youtube.com/watch?v=NXFxypRKfk8&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=7"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"v8v47ae6yf"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"A Bedtime Story"}>
              </Title>

              <Text className="text-box" content={"Jemná lyrická skladba<br>Smooth lyrical piece"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=Kfmwbxmu1So&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=2"} href={"https://www.youtube.com/watch?v=Kfmwbxmu1So&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=2"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"On The Boat"}>
              </Title>

              <Text className="text-box" content={"Epická skladba<br>Epic piece"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=auE5UjrxIm8&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=4&t=2s"} href={"https://www.youtube.com/watch?v=auE5UjrxIm8&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=4&t=2s"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"In The Mountains"}>
              </Title>

              <Text className="text-box" content={"Symfonická skladba<br>Strong symphonic piece"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=vuYKdKlkIEY&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=1"} href={"https://www.youtube.com/watch?v=vuYKdKlkIEY&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=1"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"uwy9plwpc6i"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Fragile"}>
              </Title>

              <Text className="text-box" content={"Smooth lyrical piece"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=R4Ude-R-2zo&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=5"} href={"https://www.youtube.com/watch?v=R4Ude-R-2zo&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=5"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Dark To Light"}>
              </Title>

              <Text className="text-box" content={"Epic piece"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=8wf-TESkTU4&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=6"} href={"https://www.youtube.com/watch?v=8wf-TESkTU4&list=PLkTj2Nk6txuiFQ-PKvogTBuBqWIyXZ8mY&index=6"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Meditation For Ukraine"}>
              </Title>

              <Text className="text-box" content={"Meditation for string quartet"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=hzxKxpZGxto&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=8&t=75s"} href={"https://www.youtube.com/watch?v=hzxKxpZGxto&list=PLkTj2Nk6txuh-U437FFoeaMaX6Kmy8z5K&index=8&t=75s"} target={null} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}